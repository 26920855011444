<template>
    <div>
        <form class="form-horizontal form-horizontal-text-right">
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.loanType") }}</label
                        >
                        <div class="col-md-7">
                            <select
                                v-model.number="model.loan_type_id"
                                class="form-select form-select-sm"
                                :class="{
                                    'is-invalid':
                                        errors.has('loan_type_id') ||
                                        $v.model.loan_type_id.$error
                                }"
                                @change="onChangeLoanType"
                            >
                                <option :value="null" selected>{{
                                    $t("select")
                                }}</option>
                                <option
                                    v-for="(option, index) in loanTypes"
                                    :value="option.loan_type_id"
                                    :key="index"
                                >
                                    {{
                                        locale == "kh"
                                            ? option.loan_type_kh
                                            : option.loan_type_en
                                    }}
                                </option>
                            </select>
                            <span
                                class="validate text-danger"
                                v-if="loanTypeIdErrors.length > 0"
                                >{{ loanTypeIdErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('loan_type_id')"
                            >
                                {{ errors.first("loan_type_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                        >
                            {{ $t("customerLoanRequest.requestDate") }}
                        </label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <DatePicker
                                    :value="model.requested_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeRequestDate"
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has('requested_date') ||
                                            $v.model.requested_date.$error
                                    }"
                                ></DatePicker>
                                <input
                                    v-model="model.group_id"
                                    class="form-control form-control-sm"
                                    :placeholder="
                                        isGroupLoan
                                            ? $t(
                                                  'customerLoanRequest.groupNumber'
                                              )
                                            : $t(
                                                  'customerLoanRequest.groupNumberOptional'
                                              )
                                    "
                                    :class="{
                                        'is-invalid': errors.has('group_id')
                                    }"
                                    :disabled="!isGroupLoan"
                                    ref="group_id"
                                />
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="requestedDateErrors.length > 0"
                                >{{ requestedDateErrors[0] }}</span
                            >
                            <div
                                class="text-danger"
                                v-if="errors.has('requested_date')"
                            >
                                {{ errors.first("requested_date") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.currency") }}</label
                        >
                        <div class="col-md-7 tw-whitespace-nowrap">
                            <RadioGroup
                                v-model="model.currency_id"
                                size="small"
                            >
                                <Radio
                                    class="mt-1"
                                    :label="c.currency_id"
                                    border
                                    v-for="(c, index) in currencies"
                                    :key="index"
                                >
                                    {{ c.currency_code }}
                                </Radio>
                            </RadioGroup>
                            <span
                                class="validate text-danger"
                                v-if="currencyIdErrors.length > 0"
                                >{{ currencyIdErrors[0] }}</span
                            >
                            <div
                                class="text-danger"
                                v-if="errors.has('currency_id')"
                            >
                                {{ errors.first("currency_id") }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="row"
                        :class="{
                            'mb-3': !loanCurrency
                        }"
                    >
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.loanAmount") }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="model.request_amount"
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 110%"
                                        :class="{
                                            'ivu-form-item-error':
                                                errors.has('request_amount') ||
                                                $v.model.request_amount.$error
                                        }"
                                        ref="request_amount"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.request_amount
                                                ? formatNumber(
                                                      model.request_amount,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                                <label
                                    class="form-label col-form-label required"
                                >
                                    <small style="font-size:10px">{{
                                        $t("customerLoanRequest.interestRate")
                                    }}</small></label
                                >

                                <InputNumber
                                    :max="100"
                                    v-model="model.request_interest_rate"
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    :placeholder="
                                        $t('customerLoanRequest.interestRate')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'request_interest_rate'
                                        )
                                    }"
                                    style="width: 30%"
                                    disabled
                                ></InputNumber>
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="requestAmountErrors.length > 0"
                                >{{ requestAmountErrors[0] }}</span
                            >
                            <div
                                class="text-danger tw-text-right"
                                v-if="errors.has('request_interest_rate')"
                            >
                                {{ errors.first("request_interest_rate") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="loanCurrency">
                        <label
                            class="form-label col-form-label col-md-4"
                        ></label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <span class="badge bg-success rounded-pill"
                                    >Min amount
                                    {{
                                        formatNumber(
                                            loanCurrency.min_size,
                                            model.currency_id
                                        )
                                    }}
                                    Max amount
                                    {{
                                        formatNumber(
                                            loanCurrency.max_size,
                                            model.currency_id
                                        )
                                    }}</span
                                >
                                <span class="badge bg-blue rounded-pill"
                                    >Min rate {{ loanCurrency.min_rate }} Max
                                    rate {{ loanCurrency.max_rate }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.co") }}</label
                        >
                        <div class="col-md-7">
                            <Select
                                v-model="model.emp_co_id"
                                filterable
                                clearable
                                :remote-method="loadEmployee"
                                :loading="employeeLoading"
                                :placeholder="
                                    $t('customerLoanRequest.searchEmployee')
                                "
                                :class="{
                                    'ivu-form-item-error':
                                        errors.has('emp_co_id') ||
                                        $v.model.emp_co_id.$error
                                }"
                            >
                                <Option
                                    v-for="(option, index) in employees"
                                    :value="option.employee_id"
                                    :key="index"
                                >
                                    {{
                                        locale == "kh"
                                            ? option.employee_name_kh
                                            : option.employee_name_en
                                    }}
                                </Option>
                            </Select>
                            <span
                                class="validate text-danger"
                                v-if="empCoIdErrors.length > 0"
                                >{{ empCoIdErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('emp_co_id')"
                            >
                                {{ errors.first("emp_co_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.bmName") }}</label
                        >
                        <div class="col-md-7">
                            <Select
                                v-model="model.emp_bm_id"
                                filterable
                                clearable
                                :remote-method="loadBmEmployee"
                                :loading="bmEmployeeLoading"
                                :placeholder="
                                    $t('customerLoanRequest.searchBm')
                                "
                                :class="{
                                    'ivu-form-item-error':
                                        errors.has('emp_bm_id') ||
                                        $v.model.emp_bm_id.$error
                                }"
                            >
                                <Option
                                    v-for="(option, index) in bmEmployees"
                                    :value="option.employee_id"
                                    :key="index"
                                >
                                    {{
                                        locale == "kh"
                                            ? option.employee_name_kh
                                            : option.employee_name_en
                                    }}
                                </Option>
                            </Select>
                            <span
                                class="validate text-danger"
                                v-if="empBmIdErrors.length > 0"
                                >{{ empBmIdErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('emp_co_id')"
                            >
                                {{ errors.first("emp_co_id") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{ $t("customerLoanRequest.purpose") }}</label
                        >
                        <div class="col-md-7">
                            <Select
                                v-model="model.purpose_id"
                                style="width:200px"
                                :class="{
                                    'ivu-form-item-error':
                                        errors.has('purpose_id') ||
                                        $v.model.purpose_id.$error
                                }"
                                filterable
                                clearable
                            >
                                <OptionGroup
                                    v-for="(sector, index) in purpose"
                                    :label="
                                        sector.sector_code +
                                            '|' +
                                            (locale == 'kh'
                                                ? sector.sector_kh
                                                : sector.sector_en)
                                    "
                                    :key="index"
                                >
                                    <Option
                                        v-for="(option, i) in sector.purposes"
                                        :value="option.id"
                                        :key="i"
                                        >{{
                                            option.purpose_code +
                                                "-" +
                                                (locale == "kh"
                                                    ? option.purpose_kh
                                                    : option.purpose_en)
                                        }}</Option>
                                </OptionGroup>
                            </Select>
                            <span
                                class="validate text-danger"
                                v-if="purposeIdErrors.length > 0"
                                >{{ purposeIdErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('purpose_id')"
                            >
                                {{ errors.first("purpose_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.installmentType")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-whitespace-nowrap tw-space-x-1 tw-justify-between tw-items-center"
                            >
                                <RadioGroup
                                    v-model="model.request_installment_type_id"
                                    size="small"
                                    type="button"
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has(
                                                'request_installment_type_id'
                                            ) ||
                                            $v.model.request_installment_type_id
                                                .$error
                                    }"
                                >
                                    <Radio
                                        class="mt-1"
                                        :label="c.id"
                                        v-for="(c, index) in installmentTypes"
                                        :key="index"
                                    >
                                        {{ c.label }}
                                    </Radio>
                                </RadioGroup>
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="requestInstallmentTypeIdErrors.length > 0"
                                >{{ requestInstallmentTypeIdErrors[0] }}</span
                            >
                            <div
                                class="text-danger"
                                v-if="errors.has('request_installment_type_id')"
                            >
                                {{
                                    errors.first("request_installment_type_id")
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t(
                                    "customerLoanRequest.installmentTypeDuration"
                                )
                            }}
                            (T):</label
                        >
                        <div class="col-md-7">
                            <InputNumber
                                v-model.number="model.request_loan_duration"
                                :min="1"
                                :class="{
                                    'ivu-form-item-error':
                                        errors.has('request_loan_duration') ||
                                        $v.model.request_loan_duration.$error
                                }"
                            ></InputNumber>
                            <br />
                            <span
                                class="validate text-danger"
                                v-if="requestLoanDurationErrors.length > 0"
                                >{{ requestLoanDurationErrors[0] }}</span
                            >
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="form-label col-form-label col-md-4"
                            >{{
                                $t("customerLoanRequest.firstInstallmentAmount")
                            }}
                            (R):</label
                        >
                        <div class="col-md-7">
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="
                                        model.first_installment_amount
                                    "
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 107%"
                                    ref="first_installment_amount"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.first_installment_amount
                                            ? formatNumber(
                                                  model.first_installment_amount,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </form>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { sortBy, debounce } from "lodash";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
    name: "loanInformation",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            employeeLoading: false,
            bmEmployeeLoading: false,
            loanCurrency: null,
            errors: new Errors(),
            installment_types: {
                daily: {
                    id: 1,
                    label: "Daily"
                },
                weekly: {
                    id: 2,
                    label: "Weekly"
                },
                monthly: {
                    id: 3,
                    label: "Monthly"
                }
            }
        };
    },
    created() {
        this.model = this.value;
    },
    validations: {
        model: {
            loan_type_id: { required },
            requested_date: { required },
            currency_id: { required },
            request_amount: { required },
            request_installment_type_id: { required },
            request_loan_duration: { required },
            request_interest_rate: { required },
            emp_co_id: { required },
            emp_bm_id: { required },
            purpose_id: { required }
        },
        form: [
            "model.loan_type_id",
            "model.requested_date",
            "model.currency_id",
            "model.request_amount",
            "model.request_installment_type_id",
            "model.request_loan_duration",
            "model.request_interest_rate",
            "model.emp_co_id",
            "model.emp_bm_id",
            "model.purpose_id"
        ]
    },
    computed: {
        ...mapState("creditOperation/customerLoanRequest", [
            "edit_data",
            "employees",
            "bmEmployees",
            "currencies",
            "loanTypes",
            "purpose",
            "repaymentMethods"
        ]),
        ...mapGetters(["formatNumber"]),
        installmentTypes() {
            let newObj = [];

            let loanType = this.loanTypes.find(
                l => l.loan_type_id == this.model.loan_type_id
            );

            if (loanType) {
                let { daily, monthly, weekly } = loanType;

                if (daily) {
                    newObj.push(this.installment_types["daily"]);
                }
                if (monthly) {
                    newObj.push(this.installment_types["monthly"]);
                }
                if (weekly) {
                    newObj.push(this.installment_types["weekly"]);
                }

                return sortBy(newObj, ["id"]);
            }

            return Object.values(this.installment_types);
        },
        locale() {
            return this.$root.$i18n.locale;
        },
        loanTypeIdErrors() {
            const errors = [];
            if (!this.$v.model.loan_type_id.$error) return errors;
            !this.$v.model.loan_type_id.required && errors.push("Required");
            return errors;
        },
        requestedDateErrors() {
            const errors = [];
            if (!this.$v.model.requested_date.$error) return errors;
            !this.$v.model.requested_date.required && errors.push("Required");
            return errors;
        },
        currencyIdErrors() {
            const errors = [];
            if (!this.$v.model.currency_id.$error) return errors;
            !this.$v.model.currency_id.required && errors.push("Required");
            return errors;
        },
        requestAmountErrors() {
            const errors = [];
            if (!this.$v.model.request_amount.$error) return errors;
            !this.$v.model.request_amount.required && errors.push("Required");
            return errors;
        },
        requestInterestRateErrors() {
            const errors = [];
            if (!this.$v.model.request_interest_rate.$error) return errors;
            !this.$v.model.request_interest_rate.required &&
                errors.push("Required");
            return errors;
        },
        empCoIdErrors() {
            const errors = [];
            if (!this.$v.model.emp_co_id.$error) return errors;
            !this.$v.model.emp_co_id.required && errors.push("Required");
            return errors;
        },
        empBmIdErrors() {
            const errors = [];
            if (!this.$v.model.emp_bm_id.$error) return errors;
            !this.$v.model.emp_bm_id.required && errors.push("Required");
            return errors;
        },
        requestInstallmentTypeIdErrors() {
            const errors = [];
            if (!this.$v.model.request_installment_type_id.$error)
                return errors;
            !this.$v.model.request_installment_type_id.required &&
                errors.push("Required");
            return errors;
        },
        requestLoanDurationErrors() {
            const errors = [];
            if (!this.$v.model.request_loan_duration.$error) return errors;
            !this.$v.model.request_loan_duration.required &&
                errors.push("Required");
            return errors;
        },
        purposeIdErrors() {
            const errors = [];
            if (!this.$v.model.purpose_id.$error) return errors;
            !this.$v.model.purpose_id.required && errors.push("Required");
            return errors;
        },
        isGroupLoan() {
            if (this.model.loan_type_id) {
                return this.loanTypes.find(
                    l => l.loan_type_id == this.model.loan_type_id
                ).is_group_loan;
            }
            return false;
        }
    },
    methods: {
        ...mapActions("creditOperation/customerLoanRequest", [
            "getEmployees",
            "getBmEmployees",
            "getCustomer"
        ]),
        validateForm() {
            this.$v.form.$touch();
            var isValid = !this.$v.form.$invalid;
            return isValid;
        },
        loadEmployee: debounce(function(query) {
            this.employeeLoading = true;
            this.getEmployees({ search: query }).finally(() => {
                this.employeeLoading = false;
            });
        }, 1000),
        loadBmEmployee: debounce(function(query) {
            this.bmEmployeeLoading = true;
            this.getBmEmployees({ search: query }).finally(() => {
                this.bmEmployeeLoading = false;
            });
        }, 1000),
        onChangeRequestDate(date) {
            this.model.requested_date = date;
            this.model.first_installment_date = moment(date, "DD-MM-YYYY")
                .add(1, "days")
                .format("DD-MM-YYYY");
        },
        onChangeFirstInstallmentDate(date) {
            this.model.first_installment_date = date;
        },
        onChangeLoanType() {
            this.model.installment_type_id = null;
            this.model.group_id = null;
            let loan_type = this.loanTypes.find(
                l => l.loan_type_id == this.model.loan_type_id
            );
            if (loan_type) {
                this.model.request_loan_duration =
                    loan_type.default_number_of_cycle;
                this.model.currency_id = loan_type.default_currency_id;
                this.model.request_interest_rate =
                    loan_type.default_interest_rate;
                this.model.loan_type_cur_id = loan_type.loan_type_cur_id;
                if (loan_type.is_group_loan) {
                    this.$nextTick(function() {
                        this.$refs.group_id.focus();
                    });
                } else {
                    this.$nextTick(function() {
                        this.$refs.request_amount.focus();
                    });
                }
            }
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        },
        "model.loan_type_id": function(val) {
            if (val) {
                let loan_type = this.loanTypes.find(l => l.loan_type_id == val);
                if (loan_type) {
                    this.loanCurrency = loan_type.loan_currency;
                }
            }
        },
        installmentTypes: function(val) {
            if (val.length === 1) {
                this.model.installment_type_id = val[0].id;
            }
        }
    }
};
</script>
