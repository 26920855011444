<template>
    <div>
        <fieldset class="mb-4">
            <legend class="text-blue">
                {{ $t("customerLoanRequest.debtsOwed") }}
            </legend>
            <div class="table-ui-detail">
                <table>
                    <thead>
                        <tr>
                            <th width="30%">
                                {{ $t("customerLoanRequest.institutions") }}
                            </th>
                            <th width="20%">
                                {{
                                    $t(
                                        "customerLoanRequest.loanDurationInMonth"
                                    )
                                }}
                            </th>
                            <th width="30%">
                                {{ $t("customerLoanRequest.monthlyPayment") }}
                            </th>
                            <th width="20%">
                                {{
                                    $t("customerLoanRequest.outstandingBalance")
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Input
                                    v-model.number="model.mfi_owe_due_1"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'mfi_owe_due_1'
                                        )
                                    }"
                                    :placeholder="
                                        $t('customerLoanRequest.institutions1')
                                    "
                                >
                                </Input>
                            </td>
                            <td>
                                <InputNumber
                                    v-model.number="
                                        model.mfi_owe_due_1_duration
                                    "
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 80%"
                                ></InputNumber>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_1_installment
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_1_installment'
                                            )
                                        }"
                                        ref="mfi_owe_due_1_installment"
                                        @on-change="onCalculateOweDue"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_1_installment
                                                ? formatNumber(
                                                      model.mfi_owe_due_1_installment,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_1_outstanding
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_1_outstanding'
                                            )
                                        }"
                                        ref="mfi_owe_due_1_outstanding"
                                        @on-change="onCalculateOutstanding"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_1_outstanding
                                                ? formatNumber(
                                                      model.mfi_owe_due_1_outstanding,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Input
                                    v-model.number="model.mfi_owe_due_2"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'mfi_owe_due_2'
                                        )
                                    }"
                                    :placeholder="
                                        $t('customerLoanRequest.institutions2')
                                    "
                                >
                                </Input>
                            </td>
                            <td>
                                <InputNumber
                                    v-model.number="
                                        model.mfi_owe_due_2_duration
                                    "
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 80%"
                                ></InputNumber>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_2_installment
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_2_installment'
                                            )
                                        }"
                                        ref="mfi_owe_due_2_installment"
                                        @on-change="onCalculateOweDue"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_2_installment
                                                ? formatNumber(
                                                      model.mfi_owe_due_2_installment,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_2_outstanding
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_2_outstanding'
                                            )
                                        }"
                                        ref="mfi_owe_due_2_outstanding"
                                        @on-change="onCalculateOutstanding"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_2_outstanding
                                                ? formatNumber(
                                                      model.mfi_owe_due_2_outstanding,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Input
                                    v-model.number="model.mfi_owe_due_3"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'mfi_owe_due_3'
                                        )
                                    }"
                                    :placeholder="
                                        $t('customerLoanRequest.institutions3')
                                    "
                                >
                                </Input>
                            </td>
                            <td>
                                <InputNumber
                                    v-model.number="
                                        model.mfi_owe_due_3_duration
                                    "
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 80%"
                                ></InputNumber>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_3_installment
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_3_installment'
                                            )
                                        }"
                                        ref="mfi_owe_due_3_installment"
                                        @on-change="onCalculateOweDue"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_3_installment
                                                ? formatNumber(
                                                      model.mfi_owe_due_3_installment,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                            <td>
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.mfi_owe_due_3_outstanding
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'mfi_owe_due_3_outstanding'
                                            )
                                        }"
                                        ref="mfi_owe_due_3_outstanding"
                                        @on-change="onCalculateOutstanding"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.mfi_owe_due_3_outstanding
                                                ? formatNumber(
                                                      model.mfi_owe_due_3_outstanding,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th
                                width="50%"
                                colspan="2"
                                class="tw-text-right text-blue tw-font-bold"
                            >
                                {{
                                    $t(
                                        "customerLoanRequest.totalDebtToBePaidMonthly"
                                    )
                                }}
                            </th>
                            <th width="30%">
                                (D):
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="
                                            model.total_owe_due_to_be_paid
                                        "
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'total_owe_due_to_be_paid'
                                            )
                                        }"
                                        ref="total_owe_due_to_be_paid"
                                        size="large"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.total_owe_due_to_be_paid
                                                ? formatNumber(
                                                      model.total_owe_due_to_be_paid,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </th>
                            <th width="20%">
                                (E):
                                <Poptip trigger="focus">
                                    <InputNumber
                                        v-model.number="model.total_outstanding"
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error': errors.has(
                                                'total_outstanding'
                                            )
                                        }"
                                        ref="total_outstanding"
                                        size="large"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </InputNumber>
                                    <div slot="content">
                                        {{
                                            model.total_outstanding
                                                ? formatNumber(
                                                      model.total_outstanding,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </fieldset>
        <fieldset class="mb-2">
            <legend class="text-blue">
                {{ $t("customerLoanRequest.abilityToBorrowMoney") }}
            </legend>
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-9 mb-4 mt-2">
                    <div class="tw-font-bold">
                        {{ $t("customerLoanRequest.disbursalAmount") }} = (C x T
                        x 70%) – E
                    </div>
                </div>
                <div></div>
                <div></div>
            </div>
            <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-items-center mb-3">
                <div class="tw-text-right">
                    {{ $t("customerLoanRequest.disbursalAmount") }}
                </div>
                <div>
                    <Poptip trigger="focus">
                        <InputNumber
                            v-model.number="model.disbursal_amount"
                            :placeholder="$t('customerLoanRequest.enterNumber')"
                            style="width: 180%"
                            :class="{
                                'ivu-form-item-error': errors.has(
                                    'disbursal_amount'
                                )
                            }"
                            ref="disbursal_amount"
                            size="large"
                        >
                            <i
                                class="fas fa-dollar-sign"
                                slot="prefix"
                                v-if="model.currency_id == 1"
                            ></i>
                            <i
                                class="far fa-registered"
                                slot="prefix"
                                v-if="model.currency_id == 2"
                            ></i>
                            <i
                                class="fas fa-bold"
                                slot="prefix"
                                v-if="model.currency_id == 3"
                            ></i>
                        </InputNumber>
                        <div slot="content">
                            {{
                                model.disbursal_amount
                                    ? formatNumber(
                                          model.disbursal_amount,
                                          model.currency_id
                                      )
                                    : $t("customerLoanRequest.enterNumber")
                            }}
                        </div>
                    </Poptip>
                </div>
                <div class="tw-text-left"></div>
                <div></div>
            </div>
            <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-items-center mb-2">
                <div class="tw-text-right">
                    {{ $t("customerLoanRequest.installmentPossibility") }} = ((C
                    - D) ៖ (R x 2)) x 100
                </div>
                <div>
                    <InputNumber
                        v-model.number="model.installment_possibility"
                        :placeholder="$t('customerLoanRequest.enterNumber')"
                        style="width: 100%"
                        size="large"
                    >
                    </InputNumber>
                </div>
                % (>=100%)
                <div class="tw-text-left"></div>
                <div></div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapGetters } from "vuex";

export default {
    name: "debtsOwedAndAbilityToBorrowMoney",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapGetters(["formatNumber"]),
        netProfit() {
            return (
                (isNaN(parseFloat(this.model.total_monthly_income))
                    ? 0
                    : parseFloat(this.model.total_monthly_income)) -
                (isNaN(parseFloat(this.model.total_monthly_expense))
                    ? 0
                    : parseFloat(this.model.total_monthly_expense))
            );
        }
    },
    methods: {
        onCalculateOweDue() {
            this.model.total_owe_due_to_be_paid =
                (isNaN(parseFloat(this.model.mfi_owe_due_1_installment))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_1_installment)) +
                (isNaN(parseFloat(this.model.mfi_owe_due_2_installment))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_2_installment)) +
                (isNaN(parseFloat(this.model.mfi_owe_due_3_installment))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_3_installment));
        },
        onCalculateOutstanding() {
            this.model.total_outstanding =
                (isNaN(parseFloat(this.model.mfi_owe_due_1_outstanding))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_1_outstanding)) +
                (isNaN(parseFloat(this.model.mfi_owe_due_2_outstanding))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_2_outstanding)) +
                (isNaN(parseFloat(this.model.mfi_owe_due_3_outstanding))
                    ? 0
                    : parseFloat(this.model.mfi_owe_due_3_outstanding));
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        },
        "model.request_loan_duration": function(val) {
            this.model.disbursal_amount =
                ((isNaN(parseFloat(this.netProfit))
                    ? 0
                    : parseFloat(this.netProfit)) *
                    (isNaN(parseFloat(val)) ? 0 : parseInt(val)) *
                    70) /
                    100 -
                (isNaN(parseFloat(this.model.total_outstanding))
                    ? 0
                    : parseFloat(this.model.total_outstanding));
        },
        "model.total_outstanding": function(val) {
            this.model.disbursal_amount =
                ((isNaN(parseFloat(this.netProfit))
                    ? 0
                    : parseFloat(this.netProfit)) *
                    (isNaN(parseFloat(this.model.request_loan_duration))
                        ? 0
                        : parseInt(this.model.request_loan_duration)) *
                    70) /
                    100 -
                (isNaN(parseFloat(val)) ? 0 : parseFloat(val));
        },
        netProfit: function(val) {
            this.model.disbursal_amount =
                ((isNaN(parseFloat(val)) ? 0 : parseFloat(val)) *
                    (isNaN(parseFloat(this.model.request_loan_duration))
                        ? 0
                        : parseInt(this.model.request_loan_duration)) *
                    70) /
                    100 -
                (isNaN(parseFloat(this.model.total_outstanding))
                    ? 0
                    : parseFloat(this.model.total_outstanding));
            this.model.installment_possibility =
                (isNaN(parseFloat(val)) ? 0 : parseFloat(val)) -
                    (isNaN(parseFloat(this.model.total_owe_due_to_be_paid))
                        ? 0
                        : parseFloat(this.model.total_owe_due_to_be_paid)) ==
                0
                    ? 0
                    : parseInt(
                          (((isNaN(parseFloat(val)) ? 0 : parseFloat(val)) -
                              (isNaN(
                                  parseFloat(
                                      this.model.total_owe_due_to_be_paid
                                  )
                              )
                                  ? 0
                                  : parseFloat(
                                        this.model.total_owe_due_to_be_paid
                                    ))) /
                              ((isNaN(
                                  parseFloat(
                                      this.model.first_installment_amount
                                  )
                              )
                                  ? 0
                                  : parseFloat(
                                        this.model.first_installment_amount
                                    )) *
                                  2)) *
                              100
                      );
        },
        "model.total_owe_due_to_be_paid": function(val) {
            this.model.installment_possibility =
                (isNaN(parseFloat(this.netProfit))
                    ? 0
                    : parseFloat(this.netProfit)) -
                    (isNaN(parseFloat(val)) ? 0 : parseFloat(val)) ==
                0
                    ? 0
                    : parseInt(
                          (((isNaN(parseFloat(this.netProfit))
                              ? 0
                              : parseFloat(this.netProfit)) -
                              (isNaN(parseFloat(val)) ? 0 : parseFloat(val))) /
                              ((isNaN(
                                  parseFloat(
                                      this.model.first_installment_amount
                                  )
                              )
                                  ? 0
                                  : parseFloat(
                                        this.model.first_installment_amount
                                    )) *
                                  2)) *
                              100
                      );
        },
        "model.first_installment_amount": function(val) {
            this.model.installment_possibility =
                (isNaN(parseFloat(this.netProfit))
                    ? 0
                    : parseFloat(this.netProfit)) -
                    (isNaN(parseFloat(val)) ? 0 : parseFloat(val)) ==
                0
                    ? 0
                    : parseInt(
                          (((isNaN(parseFloat(this.netProfit))
                              ? 0
                              : parseFloat(this.netProfit)) -
                              (isNaN(
                                  parseFloat(
                                      this.model.total_owe_due_to_be_paid
                                  )
                              )
                                  ? 0
                                  : parseFloat(
                                        this.model.total_owe_due_to_be_paid
                                    ))) /
                              ((isNaN(parseFloat(val)) ? 0 : parseFloat(val)) *
                                  2)) *
                              100
                      );
        }
    }
};
</script>
