<template>
    <div>
        <div class="table-ui-detail">
            <table>
                <thead>
                    <tr>
                        <th width="30%">
                            {{ $t("customerLoanRequest.sourceOfIncome") }}
                        </th>
                        <th width="20%">
                            {{
                                $t("customerLoanRequest.averageIncomePerMonth")
                            }}
                        </th>
                        <th width="30%">
                            {{ $t("customerLoanRequest.sourceOfExpense") }}
                        </th>
                        <th width="20%">
                            {{ $t("customerLoanRequest.expenses") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Input
                                v-model.number="model.source_of_income_1"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_income_1'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.firstSourceOfIncome'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.average_imcome_1"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'average_imcome_1'
                                        )
                                    }"
                                    ref="average_imcome_1"
                                    @on-change="onCalculateIncome"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.average_imcome_1
                                            ? formatNumber(
                                                  model.average_imcome_1,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                        <td>
                            <Input
                                v-model.number="model.source_of_expense_1"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_expense_1'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.firstSourceOfExpense'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.expense_1"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'expense_1'
                                        )
                                    }"
                                    ref="expense_1"
                                    @on-change="onCalculateExpenses"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.expense_1
                                            ? formatNumber(
                                                  model.expense_1,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input
                                v-model.number="model.source_of_income_2"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_income_2'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.secondSourceOfIncome'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.average_imcome_2"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'average_imcome_2'
                                        )
                                    }"
                                    ref="average_imcome_2"
                                    @on-change="onCalculateIncome"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.average_imcome_2
                                            ? formatNumber(
                                                  model.average_imcome_2,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                        <td>
                            <Input
                                v-model.number="model.source_of_expense_2"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_expense_2'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.secondSourceOfExpense'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.expense_2"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'expense_2'
                                        )
                                    }"
                                    ref="expense_2"
                                    @on-change="onCalculateExpenses"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.expense_2
                                            ? formatNumber(
                                                  model.expense_2,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input
                                v-model.number="model.source_of_income_3"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_income_3'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.thirdSourceOfIncome'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.average_imcome_3"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'average_imcome_3'
                                        )
                                    }"
                                    ref="average_imcome_3"
                                    @on-change="onCalculateIncome"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.average_imcome_3
                                            ? formatNumber(
                                                  model.average_imcome_3,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                        <td>
                            <Input
                                v-model.number="model.source_of_expense_3"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_expense_3'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.thirdSourceOfExpense'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.expense_3"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'expense_3'
                                        )
                                    }"
                                    ref="expense_3"
                                    @on-change="onCalculateExpenses"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.expense_3
                                            ? formatNumber(
                                                  model.expense_3,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input
                                v-model.number="model.source_of_income_4"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_income_4'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.fourthSourceOfIncome'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.average_imcome_4"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'average_imcome_4'
                                        )
                                    }"
                                    ref="average_imcome_4"
                                    @on-change="onCalculateIncome"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.average_imcome_4
                                            ? formatNumber(
                                                  model.average_imcome_4,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                        <td>
                            <Input
                                v-model.number="model.source_of_expense_4"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'source_of_expense_4'
                                    )
                                }"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.fourthSourceOfExpense'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.expense_4"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'expense_4'
                                        )
                                    }"
                                    ref="expense_4"
                                    @on-change="onCalculateExpenses"
                                    style="width: 120%"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.expense_4
                                            ? formatNumber(
                                                  model.expense_4,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th width="30%" class="tw-text-right text-info tw-font-bold">
                            {{ $t("customerLoanRequest.totalMonthlyIncome") }}
                            (A):
                        </th>
                        <th width="20%">
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.total_monthly_income"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 107%"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'total_monthly_income'
                                        )
                                    }"
                                    ref="total_monthly_income"
                                    size="large"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.total_monthly_income
                                            ? formatNumber(
                                                  model.total_monthly_income,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </th>
                        <th class="tw-text-right text-warning tw-font-bold" width="30%">
                            {{ $t("customerLoanRequest.totalMonthlyExpenses") }}
                            (B):
                        </th>
                        <th width="20%">
                            <Poptip trigger="focus">
                                <InputNumber
                                    v-model.number="model.total_monthly_expense"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 107%"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'total_monthly_expense'
                                        )
                                    }"
                                    ref="total_monthly_expense"
                                    size="large"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </InputNumber>
                                <div slot="content">
                                    {{
                                        model.total_monthly_expense
                                            ? formatNumber(
                                                  model.total_monthly_expense,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </th>
                    </tr>
                    <tr>
                        <th width="50%" colspan="2" class="tw-text-right text-success tw-font-bold">
                            {{ $t("customerLoanRequest.netProfit") }}
                            (C = A – B):
                        </th>
                        <th width="50%" colspan="2">
                            <Input
                                :value="netProfit"
                                style="width: 100%"
                                size="large"
                                disabled
                            >
                            </Input>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapGetters } from "vuex";

export default {
    name: "sourceOfIncomeAndExpenses",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapGetters(["formatNumber"]),
        netProfit() {
            return (
                (isNaN(parseFloat(this.model.total_monthly_income))
                    ? 0
                    : parseFloat(this.model.total_monthly_income)) -
                (isNaN(parseFloat(this.model.total_monthly_expense))
                    ? 0
                    : parseFloat(this.model.total_monthly_expense))
            );
        }
    },
    methods: {
        onCalculateIncome() {
            this.model.total_monthly_income =
                (isNaN(parseFloat(this.model.average_imcome_1))
                    ? 0
                    : parseFloat(this.model.average_imcome_1)) +
                (isNaN(parseFloat(this.model.average_imcome_2))
                    ? 0
                    : parseFloat(this.model.average_imcome_2)) +
                (isNaN(parseFloat(this.model.average_imcome_3))
                    ? 0
                    : parseFloat(this.model.average_imcome_3)) +
                (isNaN(parseFloat(this.model.average_imcome_4))
                    ? 0
                    : parseFloat(this.model.average_imcome_4));
        },
        onCalculateExpenses() {
            this.model.total_monthly_expense =
                (isNaN(parseFloat(this.model.expense_1))
                    ? 0
                    : parseFloat(this.model.expense_1)) +
                (isNaN(parseFloat(this.model.expense_2))
                    ? 0
                    : parseFloat(this.model.expense_2)) +
                (isNaN(parseFloat(this.model.expense_3))
                    ? 0
                    : parseFloat(this.model.expense_3)) +
                (isNaN(parseFloat(this.model.expense_4))
                    ? 0
                    : parseFloat(this.model.expense_4));
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    }
};
</script>
